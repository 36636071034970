<template>
    <v-dialog v-model="dialog" width="500">
        <template v-slot:activator="{ on, attrs }">
            <v-btn color="secondary" class="mr-5" v-on="on" elevation="1">Категории</v-btn>
        </template>

        <v-card>
            <v-card-title class="headline grey lighten-2">
                Категории
            </v-card-title>
            <v-card-text>

                <template>
                    <v-simple-table class="mt-5 mb-2">
                        <template>
                            <tbody ref="sort">
                            <tr
                                    v-for="(item, index) in categories"
                                    class="sortableRow"
                                    :key="item.id"
                            >
                                <!--                            <td>{{index}}</td>-->
                                <td class="px-1" style="width: 0.1%">
                                    <v-btn style="cursor: move" icon class="sortHandle"><v-icon>mdi-drag</v-icon></v-btn>
                                </td>
                                <td>
                                    <input v-if="editable === item.id" type="text" v-model="item.name" style="background-color: yellow">
                                    <div v-else>{{ item.name }}</div>
                                </td>
                                <td style="text-align: end;">
                                    <v-icon color="gray" v-on:click="editable = item.id" class="mr-1">mdi-circle-edit-outline</v-icon>
                                    <v-icon color="gray" v-on:click="deleteCategory(index)">mdi-delete-circle-outline</v-icon>
                                </td>
                            </tr>
                            </tbody>
                        </template>
                    </v-simple-table>




                    <div v-if="!loading">
                        <v-btn color="primary" v-if="isListChanged"  v-on:click="saveCategories()" elevation="0">Сохранить</v-btn>
                        <v-btn color="primary" v-else disabled v-on:click="saveCategories()" elevation="0">Сохранить</v-btn>
                        <v-text-field
                                style="width: 63%; display: inline-block"
                                class="ml-6"
                                label="Новая категория"
                                append-icon="mdi-plus"
                                @click:append="addCategory"
                                v-model="newCategory"
                                v-if="categories"
                        ></v-text-field>
                    </div>
                    <v-progress-circular v-else indeterminate :size="20" :width="3"></v-progress-circular>



                </template>

            </v-card-text>

        </v-card>
    </v-dialog>
</template>

<script>
    import Sortable from 'sortablejs';
    import Loader from "./Loader";
    export default {
        name: "ProjectCategories",
        components: {Loader},
        data() {
            return {
                editable: null,
                dialog: false,
                loading: true,
                newCategory: '',
                categories: null,
                old_categories: null
            }
        },
        methods: {
            loadCategories() {
                this.loading = true;
                this.$store.dispatch('getCategories').then(res => {
                    this.loading = false;
                    this.categories = res.categories;
                    this.old_categories = this.cloneObject(res.categories)
                })
            },
            saveCategories() {
                this.editable = null;
                this.loading = true;
                this.$db.collection('settings').doc('project_categories').set({categories: this.categories}).then(() => {
                    this.loading = false;
                    this.editable = null;
                    this.old_categories = this.cloneObject(this.categories)
                    this.$store.commit('categories', {categories: this.categories})
                    this.$store.commit('alert', { message: 'Категории обновлены.'});

                })

            },
            addCategory() {
                if (this.newCategory === '') return;
                this.categories.push({name: this.newCategory, id: this.randomID()});
                this.newCategory = ''
                this.editable = null;
            },
            deleteCategory(index) {
                if(confirm('Удалить категорию -'+this.categories[index].name+'-')) this.categories.splice(index, 1)
            },
            dragReorder({oldIndex, newIndex}) {
                const movedItem = this.categories.splice(oldIndex, 1)[0]
                this.categories.splice(newIndex, 0, movedItem)
            },
        },
        computed: {
          isListChanged() {
              return this.compareObjects(this.categories, this.old_categories);
          }
        },
        watch: {
            dialog: function (n, o) {
                if (n) {
                    this.editable = null;
                    this.categories = null;
                    this.loadCategories()

                    const that = this;
                    // Init sorting
                    function initSort() {
                        var el = that.$refs.sort;
                        if (el) {
                            var sortable = Sortable.create(el, {
                                draggable: '.sortableRow',
                                handle: '.sortHandle',
                                onEnd: that.dragReorder,
                            });
                        } else {
                            console.log(el)
                            setTimeout(initSort, 1000);
                        }
                    }
                    initSort()

                }

        },
        mounted() {

        }
    }}
</script>

<style scoped>

</style>
