<template>
    <div>
        <v-row no-gutters fluid class="spacing-playground my-6 p-0">
            <v-col cols="12" sm="8">
                <div class="text-sm-h4">{{titles[$route.params.collection]}}</div>
            </v-col>
            <v-col cols="12" sm="4" align="right">
                <ProjectCategories v-if="$route.params.collection === 'projects'"/>
                <v-btn color="primary" elevation="1" large v-on:click="createPage">Добавить</v-btn>
            </v-col>
        </v-row>
        <v-card outlined>
            <v-simple-table>
                <template v-slot:default>
                    <thead>
                    <tr>
                        <th class="text-left">
                        </th>
                        <th v-for="lang in languages">
                            {{lang}}
                        </th>
                        <th class="text-left">
                            Статус
                        </th>
                        <th class="text-left">
                            Дата публикации
                        </th>
                        <th  style="text-align: end;">
                            Удалить
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item, itemIndex) in pages">
                        <td width="20">
                            {{itemIndex+1}}
                        </td>
                        <td v-for="lang in languages">
                            <router-link :to="collection+'/'+item.id+'/'+lang" class="text-decoration-none font-weight-bold">{{ (item[lang] != null && item[lang].title !== '') ? item[lang].title : 'Без заголовка' }}</router-link>
                        </td>
                        <td width="200">
                            <div v-if="item[mainLanguage].publish" class="circle" style="background-color: #0fcc52"></div>
                            <div v-else class="circle"></div>
                            {{item[mainLanguage].publish ? 'Опубликовано' : 'Черновик'}}
                        </td>
                        <td width="200">
                            {{item[mainLanguage].date.toDate().toISOString().substr(0, 10)}}
                        </td>
                        <td style="text-align: end;" width="100">
                            <v-icon color="gray" v-on:click="deletePage(itemIndex, item.id)">mdi-delete-circle-outline</v-icon>
                        </td>
                    </tr>
                    </tbody>
                </template>
            </v-simple-table>
            <v-progress-linear indeterminate v-if="loading"></v-progress-linear>

        </v-card>

    </div>
</template>

<script>
    import {db, increment, storage} from '../firebase';
    import ProjectCategories from "../components/ui/ProjectCategories";

    export default {
        name: "Pages",
        components: {ProjectCategories},
        data() {
            return {
                pages: [],
                loading: true,
                collection: this.$route.params.collection,
                titles: {
                    projects: 'Проекты',
                    pages: 'Страницы',
                    main: 'Главная',
                }
            }
        },
        methods: {
            async createPage() {
                let that = this;
                const counter =  await db.collection('settings').doc('counter').get();

                await db.collection('settings').doc('counter').update({
                    count: increment
                }).then(function () {
                    db.collection(that.collection).doc((counter.data().count+1).toString()).set({id: (counter.data().count+1).toString()}).then(function () {
                        // Создать папку в медиа
                        storage.ref(that.collection+'/'+(counter.data().count+1).toString()).child("null.null").put(null,null).then(e => {
                            console.log(e)
                            that.$router.push('/'+that.collection+'/'+(counter.data().count+1))

                        });
                    })
                })
            },
            async deletePage(index, id){
                let that = this;

                if(confirm("Удалить страницу навсегда?")) {
                    await db.collection(that.collection).doc(id.toString()).delete().then(function () {
                        that.pages.splice(index, 1);
                    });
                }
            },
            async getPages() {
                this.pages = []
                const snapshot = await db.collection(this.collection).orderBy("id", "desc").get();
                let that = this;
                let pages = [];
                snapshot.forEach(doc => {
                    pages.push(doc.data())
                });
                that.pages = that.sortPages(pages)
                that.loading = false
            },
            sortPages(pages) {
                let that = this;
                let sorted = pages.sort(function(y, x){
                    return x[that.mainLanguage].date - y[that.mainLanguage].date;
                });
                return sorted;
            }
        },
        computed: {
            mainLanguage() {
                return this.$store.state.languages['main_language']
            },
            languages() {
                return this.$store.state.languages['languages']
            },
        },
        mounted() {
            this.getPages()
        },
        beforeRouteUpdate(to, from, next) {
            this.loading = true
            this.collection = to.path.substring(1)
            this.getPages()
            next()
        },

    }
</script>

<style scoped>
    .circle {
        width: 10px;
        height: 10px;
        border-radius: 20px;
        background-color: #999999;
        display: inline-block;
        margin-right: 4px;
    }

</style>
